@import "../../breakpoints";

.chartContainer {
    height: 600px;
    width: 99%;

    @include bp-sm {
        height: 320px;
    }

    @include bp-md {
        height: 480px;
    }

    @include bp-lg {
        height: 720px;
    }

    @media print {
        width: 100%;
    }
}

.score {
    margin-left: 0.5rem;
    font-size: 120%;
}

.infoContainer {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem 0.5rem;
    
    @include bp-md {
        grid-template-columns: 1fr 1fr;
    }

    @media print {
        grid-template-columns: 1fr;
    }

    .title {
        margin: 0;
        font-weight: 500;
    }

    .content {
        margin: 0;
        white-space: pre-wrap;
    }
}
.categoriesContainer {
    margin-top: 3rem;

    .categorySummary {
        margin-bottom: 1rem;
    }
}
