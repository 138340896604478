@import "../../breakpoints";

.container {
  display: flex;
  flex-direction: column-reverse;

  @include bp-md {
    flex-direction: row;
  }

  .contentWrapper {
    flex: 1;
  }

  .heroWrapper {
    flex-basis: 33%;
    margin-bottom: 2rem;

    @include bp-md {
      margin-left: 2rem;
      margin-bottom: 0;
    }
  }
}

.animationContainer {
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
}
