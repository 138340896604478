@import "../../colors";
@import "../../breakpoints";

.wrapper {
  box-shadow: 0 0 0.5rem #00000022;

  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem 0 0.5rem 0;

    @include bp-md {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0.5rem 0 1.5rem 0;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .button {
        padding: 0.5em 1em;

        @include bp-md {
          padding: 1em 2em;
        }
      }

      .nextButtonDesktop {
        display: none;
        @include bp-md {
          display: inherit;
        }
      }

      .nextButtonPhone {
        @include bp-md {
          display: none;
        }
      }

      .backButton {
        margin-left: 1rem;
      }
    }

    .legalLinks {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
}
