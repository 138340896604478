$text: #161c2d;

$dark: #161c2d;
$gray: #c6d3e6;
$gray-dark: darken($gray, 30%);

$primary: #335eea;
$primary-dark: #1b2a4e;
$primary-light: lighten($primary, 30%);
$primary-lightest: lighten($primary, 40%);

$error: #a00000;
$surface: #f7f9ff;