@import "../../breakpoints";

.container {
  display: flex;
  flex-direction: column-reverse;

  @include bp-md {
    flex-direction: row;
  }
}

.cardContainer {
  flex-basis: 33%;
  margin-bottom: 2rem;

  @include bp-md {
    margin-left: 2rem;
  }

  .card {
    padding: 1rem 1rem 2rem 1rem;;
    background-color: #fff9e6;

    .title {
      font-weight: 400;
    }
  }
}

.fieldsContainer {
  flex: 1;
}

.imgContainer {
  display: none;

  @include bp-md {
    display: block;
  }
}
