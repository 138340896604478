@import "src/colors";

.header {
  width: 100%;
  display: flex;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  print-color-adjust: exact !important;

  .collapseIcon {
    height: 2rem;
    transition: transform 125ms ease-in-out;
    transform: rotate(-90deg);

    &.open {
      transform: rotate(0);
    }

    @media print {
      transform: rotate(0);
    }
  }

  .categoryHeadline {
    margin: 0.5rem 0 0.5rem 0.5rem;
    font-weight: 400;
  }

  &:hover {
    opacity: 0.75;
  }
}

.collapseContainer {
  overflow-y: hidden;
  padding-top: 1rem;

  &.isCollapsed {
    height: 0;

    @media print {
      height: inherit;
    }
  }
}

.groupContainer {
  padding-left: 1rem;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .groupHeadline {
    margin: 0;
    font-weight: 500;
  }

  .groupDetails {
    padding-left: 2rem;

    .questionContainer {
      margin-bottom: 2rem;

      .note {
        padding-left: 1rem;
        font-style: italic;
        border-left: 2px solid $gray;
        margin-top: 0.5rem;

        pre {
          margin: 0;
          white-space: pre-wrap;
        }
      }
    }
  }
}