@mixin bp-sm {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin bp-md {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin bp-lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin bp-xl {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin bp-xxl {
  @media screen and (min-width: 1400px) {
    @content;
  }
}