@import "../../colors";

.wrapper {
  display: block;
  background-color: $dark;
  box-shadow: 0 0 0.5rem #00000088;

  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  print-color-adjust: exact !important;

  @media print {
    box-shadow: none;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    color: white;

    .title {
      margin: 0;
      font-size: 1.5rem;
    }

    .logoItem {
      a {
        display: flex;
        align-items: center;

        img {
          height: 2rem;
        }
      }
    }
  }
}
