.root {
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media print {
    height: inherit;
    display: block;
  }

  .header {

  }

  .content {
    flex: 1;
    padding: 2rem 0;
    overflow-y: scroll;

    @media print {
      overflow-y: auto;
    }
  }

  .actions {
    @media print {
      display: none;
    }
  }
}
