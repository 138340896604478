@import "src/breakpoints";

.hero {
  padding: 1rem 1rem 2rem 1rem;

  .categoryTitle {
    font-weight: 400;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 2pt;
  }

  .groupTitle {
    margin-top: 0;
    font-weight: 400;
  }

  .imgContainer {
    display: none;

    @include bp-md {
      display: block;
    }
  }
}