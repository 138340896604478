@import "./fonts";
@import "./colors";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, Sans, sans-serif;
  font-size: 16px;
  line-height: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
}

pre {
  font-family: Roboto, Sans, sans-serif;
}

a {
  color: #1964ff;
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }

  &.white {
    color: white;
  }
}

@media print {
  .recharts-wrapper,
  .recharts-surface {
    @media print {
      width: calc(100%) !important;
    }
  }
}
