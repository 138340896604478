.prevPageSlide,
.currentPageSlide,
.nextPageSlide {
  transition: all 250ms ease-in-out;
  width: 100%;
}

.currentPageSlide {
  position: inherit;
  opacity: 1;
  min-height: 75vh;
}

.prevPageSlide {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.nextPageSlide {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(100%);
}
