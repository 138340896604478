.title {
  margin-top: 0.25rem;
  min-height: 8rem;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 3rem;
}

.select {
  margin-top: 2rem;
}

.description {
  margin-top: 2rem;
}