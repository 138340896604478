@use "../../colors" as *;
@use "../../breakpoints" as *;

.label {
  margin: 0 0 0.5rem 0;
  font-weight: 500;
  font-family: Roboto, Sans, sans-serif;
}

.levelWrapper {
  @include bp-md {
    width: 75%;
  }

  @include bp-lg {
    width: 50%;
  }

  .levelButton {
    font-size: inherit;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border: 1px solid $primary;
    border-radius: 1rem;
    background: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: $primary;
    margin-bottom: 0.5rem;

    transition: all 150ms ease-in-out;

    &:hover {
      background-color: $primary-lightest;
    }

    &:active {
      background-color: $primary-light;
    }

    &::before {
      content: "";
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;

      border: 2px solid $primary;

      transition: all 150ms ease-in-out;
    }

    &.active {
      background-color: $primary;
      color: white;

      &::before {
        background-color: $primary;
        border: 2px solid white;
      }
    }

    &.noData {
      margin-top: 2rem;
    }
  }
}

.error {
  color: $error;
}