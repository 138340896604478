@import "../../colors";

.links {
  display: flex;
  line-height: 1rem;

  span {
    margin: 0 0.5rem;
    color: $gray-dark;
  }

  a {
    color: $gray-dark;

    &:last-child {
      margin-right: 0;

      &::after {
        content: none;
      }
    }
  }
}