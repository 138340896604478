@import "../../colors";

.button {
  color: $primary;
  border: 1px solid $primary;
  cursor: pointer;
  padding: 1rem 2rem;
  background: none;
  font-family: Roboto, Sans, sans-serif;
  font-weight: 600;
  letter-spacing: 1pt;

  transition: all 100ms ease-in-out;

  &:hover {
    background-color: $primary;
    color: white;
  }

  &:active {
    background-color: $primary-light;
  }
}
