@import "../../breakpoints";

.container {
  margin: 0 1rem;

  @include bp-sm {
    margin: 0 auto;
    width: 540px;
  }

  @include bp-md {
    width: 720px;
  }

  @include bp-lg {
    width: 960px;
  }

  @include bp-xl {
    width: 1140px;
  }

  @include bp-xxl {
    width: 1320px;
  }
}
