@import "../../colors";

.inputContainer {
  margin-bottom: 1rem;

  &.error {
    .label {
      color: $error;
    }

    .input {
      border-color: $error;
    }
  }

  .label {
    font-weight: 500;
    font-family: Roboto, Sans, sans-serif;
    display: block;

    &.required {
      &::after {
        content: '*';
        margin-left: 0.5rem;
        color: $error;
      }
    }
  }

  .input {
    display: block;
    width: 100%;
    border: 1px solid $dark;
    padding: 0.75rem 1rem;
    background-color: $surface;
    font-family: Sans, sans-serif;
    outline-color: $primary;

    transition: background-color 125ms ease-in-out;

    &:hover, &:focus {
      background-color: lighten($surface, 5%);
    }
  }

  .hint {
    margin-top: 0.5rem;
    line-height: 18px;
  }

  .error {
    color: $error;
  }
}