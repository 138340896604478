@use "../../breakpoints" as *;

.heroContainer {
  display: flex;
  flex-direction: column;

  margin-bottom: 2rem;

  .heroCard {
    width: 100%;
    padding: 1rem 1rem 2rem 1rem;;
    background-color: #ebf5ff;

    @include bp-md {
      width: 40vw;
    }

    @include bp-lg {
      width: 30vw;
    }

    @include bp-xl  {
      width: 20vw;
    }

    @include bp-xxl {
      width: 15vw;
    }

    .title {
      margin-bottom: 0;
      font-weight: 300;
      font-size: 1.75rem;
    }
    .subtitle {
      margin-top: 0.25rem;
      font-weight: 400;
    }
  }

  @include bp-md {
    flex-direction: row;
  }
}

.actions,
.phoneActions {
  margin: 2rem 0;

  button {
    width: 100%;
  }
}

.phoneActions {
  @include bp-md {
    display: none;
  }
}

.contentWrapper {
  @include bp-md {
    margin-left: 2rem;
  }

  .title {
    font-weight: 400;
    font-size: 3rem;
    line-height: 3rem;
  }
}

.twoColumn {
  display: flex;
  flex-direction: column-reverse;

  .textPane {
    flex: 1;
    padding-right: 2rem;

    .categoryHeadline {
      display: block;
      margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;
      font-weight: 400;
    }

    p {
      margin-top: 0;
    }
  }

  .illustrationPane {
    flex: 1;
  }

  @include bp-sm {
    flex-direction: row;
  }
}

.ctaContainer {
  margin-bottom: 10vh;
  .ctaHeadline {
    margin: 4rem 0 1rem 0;
  }

  p {
    margin-top: 0;
  }

  .ctaButtonContainer {
    margin-top: 2rem;
  }
}

.legalContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
