@import "../../colors";

.container {
  height: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
  background-color: $gray;
  margin-bottom: 1rem;

  .bar {
    background-color: $primary;
    height: 100%;
    border-radius: 0.25rem;

    transition: width 250ms ease-in-out;
  }
}